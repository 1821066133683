import React from "react";
import '../styles/main.css';
import Header from '../components/Header';
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import { graphql } from "gatsby"
import DocsTree from '../components/DocsTree'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Helmet } from 'react-helmet';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faDownload } from '@fortawesome/free-solid-svg-icons'
import '../styles/sk-changes.css'
import PageHeadings from "../components/PageHeadings";
import styles from './docs.module.css';
import logo from '../../src/images/Logo.png'

export default function DocPage(props) {
  //const { pageContext } = props;
  
  const pathname = props.location.pathname.toLowerCase();
 
  const yamlReleases = props.pageContext.docYamlPages;

  const versions = yamlReleases.map(nodes => (nodes.node.version));
  
  const page = props.data.markdownRemark; 

  let doc;

  if (pathname === '/docs/') {
    doc =
      <div>
        {
          <div className={styles.locationPathname} >
            <div dangerouslySetInnerHTML={{ __html: page.html }} className={styles.textLeft} ></div>
          </div>
        }

      </div>

  }
  else if (pathname === '/docs/release-notes/') {
    doc =
      <div>
        {
          <div className={styles.locationPathname} >
            <div>
            <h1 className={styles.frontmatterTitle}>Release Notes</h1>
            <br/>
            <p><u><b>Important Note:</b></u></p>
              <p>Patches for versions 2.9 and above <b>must</b> be applied using <a href="https://releases.sheetkraft.com/ApplyPatch.ps1" download>  ApplyPatch.ps1</a>. If you have a version lower than 2.9.3, download this file, unblock it and place it in the same folder as Web.config.</p>

              <p>To run the script, open a Powershell window with administrative rights. Go to the directory containing the script. Type <span style={{ color: '#e83e8c', wordBreak: 'break-word', fontSize: '87.5%' }}>.\ApplyPatch.ps1</span> and hit Enter. Follow any on-screen instructions.</p>

              <p> If an error is encountered when attempting to login from the addin, paste dll files from the Addin folder in the full download into %localappdata%\SheetKraft. Be careful not to replace config files.</p>

              <p> Versions 2.10 and beyond require the .NET Framework 4.7.2 for all components including the addin. To check if this is installed open a Powershell window and run the command <span style={{ color: '#e83e8c', wordBreak: 'break-word', fontSize: '87.5%' }}>(Get-ItemProperty "HKLM:SOFTWARE\Microsoft\NET Framework Setup\NDP\v4\Full").Release -ge 461808</span></p>

            </div>
            <div  className={styles.textLeft} >
            <ul className={styles.releaseContent}>
                {
                  yamlReleases.map((releases) => (
                    <li key={releases.node.id} >
                      <br/>
                      <a href={`#version-${releases.node.version.split('.').join("")}`} aria-label={`#version ${releases.node.version.split('.').join("")}`} className="custom-class"><svg aria-hidden="true" height="20" version="1.1" viewBox="0 0 16 16" width="20"><path fillRule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg></a>
                      <h2 id={`version-${releases.node.version.split('.').join("")}`} style={{display:'inline-block'}}>Version {releases.node.version}</h2>
                      <p>Released on {releases.node.published}</p>
                      <ul>
                        {releases.node.other.map((e, i) => (
                          <li style={{listStyleType:'disc'}} key={i} dangerouslySetInnerHTML={{ __html: e }}  />
                        ))}
                      </ul>
                      <ul>
                        {
                          releases.node.files && <li style={{ display: 'block' }}>
                            <p>Manual Download links (if script based download fails)</p>
                            <ul>
                              {
                                releases.node.files.includes('patch') && <li style={{ listStyleType: 'disc' }}>
                                  <a download href={`https://releases.sheetkraft.com/SheetKraft.Patch.${releases.node.version}.zip`} className="downloadLink">Patch<span className="downloadIcon"></span></a>
                                </li>
                              }
                              {
                                releases.node.files.includes('full') && <li style={{ listStyleType: 'disc' }}>
                                  <a download href={`https://releases.sheetkraft.com/SheetKraft.Full.${releases.node.version}.zip`} className="downloadLink">Full<span className="downloadIcon"></span></a>
                                </li>
                              }
                              {
                                releases.node.files.includes('setup') && <li style={{ listStyleType: 'disc' }}>
                                  <a download href={`https://releases.sheetkraft.com/SheetKraftSetup.v.${releases.node.version}.exe`} className="downloadLink">Addin Installer<span className="downloadIcon"></span></a>
                                </li>
                              }
                            </ul>
                          </li>
                        }
                      </ul>
                    </li>
                  ))
                }

              </ul>
            </div>
          </div>
        }

      </div>
  }
  else {
    doc =
      <div>
        {
          <div className={styles.locationPathname} >
            <div className={styles.textCenter}  >
              <h1 className={styles.frontmatterTitle} >{page.frontmatter.title}</h1>
            </div>
            <br />
            <div dangerouslySetInnerHTML={{ __html: page.html }} className={styles.textLeft} ></div>
          </div>
        }
      </div>
  }

  return (
    <div >

      <Helmet>
        <meta http-equiv="cache-control" content="max-age=0" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="-1" />
        <meta http-equiv="expires" content="Tue, 01 Jan 1980 11:00:00 GMT" />
        <meta http-equiv="pragma" content="no-cache" />

        <meta name="description" property="og:description" content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications" />
        <meta name="title" property="og:title" content="SheetKraft" />
        <meta name="image" property="og:image" content={logo} />
        <meta name="author" content="SheetKraft" />
        < meta name = "keywords"
        content = " automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prismno code, rapid application development,business process automation" />

        <title>SheetKraft - Docs</title>
      </Helmet>

      <Header style={{boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.2)'}}/>

      <div id="documentation">
        <div className="container">
          <div className={`row ${styles.rowStyle}`} id="myScrollSpy">

            <div className="col-sm-12 col-lg-3" >
              <div>
                <DocsTree pathname={pathname.toLowerCase()} />
              </div>
              <div>
                <PageHeadings showHeadings={versions} pathname={pathname.toLowerCase()} />
              </div>
            </div>

            <div className="col-sm-12 col-lg-9">
              <div id="doc-content" className={styles.docContent} >
                {doc}
              </div>
              <br />
            </div>

            <AnchorLink href='#myScrollSpy' className="scrollup"   > < i className="fa fa-chevron-up" > </i> </AnchorLink>

          </div>
        </div>
      </div>


      <SheetkraftFooter />

    </div>
  );
}

export const query = graphql`
query($id: String) {
  markdownRemark(id: {eq: $id}) {
    id
    frontmatter {
      title
      showHeadings
    }
    headings {
      value
    }
   tableOfContents
    html
  }
}
`
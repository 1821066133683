import React, { useState } from "react"
import { useStaticQuery, navigate, graphql } from "gatsby"
import TreeMenu from 'react-simple-tree-menu'
import styles from "./DocsTree.module.css"

const query = graphql`
query DocsTreeQuery {
  allMarkdownRemark(filter: {fields: {slug: {regex: "^/docs/"}}, frontmatter: {draft: {ne: true}}}) {
    edges {
      node {
        frontmatter {
          title
          published(formatString: "MMMM DD , YYYY")
          relativeFilePath
          order
        }
        fields {
          slug
        }
      }
    }
  }
 
}
`;

function nodesToArray(mapNode) {
   
    let nodes = Object.keys(mapNode.nodes).map(k => mapNode.nodes[k]);
    nodes.sort((a, b) => a.order - b.order);
    mapNode.nodes = nodes;
    for (let i = 0; i < nodes.length; i++) {
        nodesToArray(nodes[i]);
    }
}
function buildKeys(keys) {
    for (let i = 1; i < keys.length; i++) {
        keys[i] = keys[i - 1] + "/" + keys[i];
    }
}

String.prototype.equalsI = function (compare) {
    return this.toLowerCase() === compare.toLowerCase();
}

export default function DocsTree({ pathname }) {
    const data = useStaticQuery(query);
    //console.log(data.allIndexYaml.edges.length)
    let docs = { key: "Docs", label: "Docs", slug: "/Docs/", nodes: {}, order: 0 };
    let initOpenKeys = [];
    
    for (let i = 0; i < data.allMarkdownRemark.edges.length; i++) {
        let node = data.allMarkdownRemark.edges[i].node;
        let keys = node.frontmatter.relativeFilePath.split('/');
        keys = keys.slice(1, keys.length - 1); // first item is expected to be docs and last item index
        let obj = docs;
        let j = 0;
        for (; j < keys.length; j++) {
            let key = keys[j];
            if (obj.nodes[key] == null)
                obj.nodes[key] = { key, label: key, slug: "", nodes: {}, order: 0 };
            obj = obj.nodes[key];
        }
        obj.label = node.frontmatter.title;
        obj.slug = node.fields.slug;
        obj.order = node.frontmatter.order || 0;
        if (obj.slug.equalsI(pathname))
            initOpenKeys = keys;
    }

    const releaseNotesKey = "release-notes";
    const releaseNotesSlug = docs.slug + releaseNotesKey + "/";
    docs.nodes[releaseNotesKey] = { key: releaseNotesKey, label: "Release Notes", slug: releaseNotesSlug, nodes: {}, order: 0 };
  
    nodesToArray(docs);
    buildKeys(initOpenKeys);
    let activeKey = (pathname.equalsI(releaseNotesSlug)) ? releaseNotesKey : initOpenKeys[initOpenKeys.length - 1];  

    let [openKeys, setOpenKeys] = useState(initOpenKeys);
    let onClickItem = item => {
        // console.log(item.key, open);
        if (!pathname.equalsI(item.slug) && item.slug) {
            navigate(item.slug.toLowerCase());
        }
        else {
            let pos = openKeys.findIndex(k => k === item.key);
            if (pos >= 0)
                setOpenKeys(openKeys.slice(0, pos))
            else
                setOpenKeys([...openKeys, item.key]);
        }
    }
    if (typeof window === 'undefined') return null;
   
    return (
        <div className={`nav nav-tabs nav-stacked doc-nav tree ${styles.trees}`} >
            <TreeMenu data={docs.nodes} onClickItem={onClickItem} activeKey={activeKey} openNodes={openKeys} />
        </div>
    );
}
import React, { useState } from 'react'
// import { useStaticQuery, graphql, Link } from "gatsby"
import '../styles/main.css';
import styles from "./PageHeadings.module.css"

export default function PageHeadings(props) {

  const inputStyling={   
    width: '100%',
    border: '0 !important',
    padding: '10px',
    textIndent:'15px'
  }

  const tableOfContents = props.showHeadings;

  const [searchTerm, setSearchTerm] = useState('');
  const handleChange = e => {
    setSearchTerm(e.target.value)
  }
  const searchResults = !searchTerm
    ? tableOfContents
    : tableOfContents.filter(version => version.indexOf(searchTerm) > -1);

  return (
    <div style={{ display: props.pathname === "/docs/release-notes/" ? 'block' : 'none', marginBottom: '20px' }}>
      < ul className={`nav nav-tabs nav-stacked doc-nav list-group ${styles.ul}`} id="doc-nav" >
        <h4 className={styles.onThisPage} >
          On this page
          <hr className={styles.hrStyles} />
        </h4>

        <div>
          <input type="text" name="" id="search" value={searchTerm}
            onChange={handleChange} placeholder="Type and search" style={inputStyling}/>
          <hr style={{marginTop:'0'}}/>
        </div>

        <li className="versions">

          <ul>
            {
              searchResults.map((versions, i) => (
                <li key={i} className="versionValues " >
                  <a href={`/docs/release-notes/#version-${versions.split('.').join("")}`}

                    dangerouslySetInnerHTML={
                      {
                        __html: `<div>Version ${versions}</div>`
                      }
                    } />
                </ li>
              ))
            }
          </ul>


        </li>
      </ul>

    </div>
  )
}

